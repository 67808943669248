
import { apiEngineAccountLists, apiEngineDel } from "@/api/setting";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import { PageMode } from "@/utils/type";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class LogisticsAccount extends Vue {
    /** S Data **/

    apiEngineAccountLists = apiEngineAccountLists;

    pager: RequestPaging = new RequestPaging();
    tableData = [];

    /** E Data **/

    /** S Methods **/

    // 获取模型列表
    getExpressLists(): void {
        this.pager
            .request({
                callback: apiEngineAccountLists,
                params: {},
            })
            .then((res) => {
                this.tableData = res.lists;
            });
    }

    // 去编辑
    toEdit(id: number | any): void {
        this.$router.push({
            path: "/logistics/account_edit",
            query: {
                id: id as any,
                mode: PageMode.EDIT,
            },
        });
    }

    toDel(id: any) {
        apiEngineDel({ id: id }).then(res => {
            this.$message.success('删除成功')
            this.getExpressLists();
        })
    }

    /** E Methods **/

    /** S ods **/
    created() {
        this.getExpressLists();
    }
}
